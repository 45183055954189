import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { PathListContentProps } from '../../../interfaces';
import { getDifficultyIcon, getDifficultyName, getStatusColor, getStatusText, getPathCategoryIcon } from '../../../utils';
import { useThemedStyleFunction } from '../../theme-provider';
import { ThemedText } from '../../themed-text';
import { listContentStyle } from './list-content.style';
import { toKilometerString } from '../../../utils';
import { getTranslatedProperty } from '../../../utils/localization.utils';

const PathListContent = (props: PathListContentProps) => {
    const {difficulty, length, status, groomedAt, tags, pathCategoryId} = props;

    const [t] = useTranslation();

    const name = getTranslatedProperty('name', props);

    const DifficultyIcon = (props: any) => getDifficultyIcon(difficulty, props);
    const difficultyName = getDifficultyName(t, difficulty, false);
    const CategoryIcon = (props: any) => getPathCategoryIcon(pathCategoryId, props)

    const formattedLength = toKilometerString(length);

    const statusText = getStatusText(t, status, groomedAt);
    const statusColor = getStatusColor(status, groomedAt);
    
    const formattedTags = tags.map((tag => getTranslatedProperty('name', tag)?.toLowerCase()));
    const metadata = [difficultyName, ...formattedTags].join(', ');

    const style = useThemedStyleFunction(listContentStyle);

    return (
        <View style={style.container}>
            <CategoryIcon style={style.categoryIcon}/>
            <View style={style.content}>
                <View style={[style.flexRow, style.row]}>
                    <View style={style.nameWrapper}>
                        <ThemedText 
                            numberOfLines={1}
                            ellipsizeMode="tail" 
                            type="bold" 
                            style={style.title}
                        >
                            { name }
                        </ThemedText>
                    </View>
                    <View style={style.flexRow}>
                        <DifficultyIcon style={style.difficultyIcon}/>
                        <ThemedText type="bold" style={style.length}>{ formattedLength }</ThemedText>
                    </View>
                </View>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText type="light" style={style.subtitle}>{ metadata }</ThemedText>
                    <ThemedText  type="bold" style={[style.status, {color: statusColor}]}>{ statusText }</ThemedText>
                </View>
            </View>
        </View>
    );
};

export { PathListContent };