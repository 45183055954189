import { useCallback, useEffect } from "react";
import { TouchableOpacity, View, ViewProps, Dimensions } from "react-native"
import { shallow } from "zustand/shallow";
import { createStackNavigator } from '@react-navigation/stack';
import { useThemedStyleFunction, withExternalStyle, LanguageSelector } from "@miqmap/shared";
import { useStateStore, useDataStore } from "../../stores"
import { SideBar } from "../side-bar/side-bar.component"
import { SideBarDetail, SideBarList } from "./content";
import { sideBarPanelStyle } from "./side-bar-panel.style";
import { BurgerMenu, MenuMap } from "../../assets";
import { SMALL_SCREEN_WIDTH } from "../../constants"; 

type SideBarPanelProps = {
    //
} & ViewProps;

const Stack = createStackNavigator();

const SideBarPanel = (props: SideBarPanelProps) => {
    const { style: styleProp } = props;
    const { selectedType, selectedId, sideBarOpen, setSideBarOpen } = useStateStore(state => state, shallow);
    const languages = useDataStore(state => state.languages);

    // Open sidebar if closed when selecing something
    useEffect(() => {
        if (selectedId !== undefined && selectedType!== undefined) {
            setSideBarOpen(true);
        }
    }, [selectedId, selectedType, setSideBarOpen]);

    const hideSideBar = useCallback(() => {
      setSideBarOpen(false);
    }, []);

    const showSideBar = useCallback(() => {
      setSideBarOpen(true);
    }, []);

    const style = useThemedStyleFunction(sideBarPanelStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);
    const deviceWindowWidth = Dimensions.get("window").width;
    const showLanguageSelector = deviceWindowWidth <= SMALL_SCREEN_WIDTH;

    return (
      <View style={containerStyle}>
        <SideBar open={sideBarOpen} maxWidth={420}>
          <View style={style.banner}>
            <TouchableOpacity onPress={hideSideBar} >
                <MenuMap {...style.menuIcon}/>
            </TouchableOpacity>
            {showLanguageSelector && 
              <LanguageSelector 
                style={style.languageSelector} 
                showTitle={false} 
                languagesIDs={languages}/>
            }
          </View>

          <Stack.Navigator screenOptions={{headerShown: false, title: 'MiQMAP'}}>
              <Stack.Screen name="list" component={SideBarList}/>
              <Stack.Screen name="details" component={SideBarDetail}/>
          </Stack.Navigator>
        </SideBar>
        
        { !sideBarOpen && (
          <TouchableOpacity style={ style.floatingTouchable} onPress={showSideBar}>
              <BurgerMenu {...style.menuIcon} />
          </TouchableOpacity>
        )}
      </View>
    )
};

export { SideBarPanel };