import { TouchableOpacity, View, ViewProps } from "react-native"
import { useTranslation } from "react-i18next";
import { FlagSwedish, FlagEnglish, FlagGermany } from "../../assets";
import { ThemedText } from "../themed-text";
import { useThemedStyleFunction } from "../theme-provider";
import { languageSelectorStyle } from "./language-selector.style";
import { withExternalStyle } from "../../utils";
import { useState, useEffect } from "react";

type LanguageSelectorProps = ViewProps & {
    inverted?: boolean;
    showTitle?: boolean;
    languagesIDs?: string[];
};


const LanguageSelector = (props: LanguageSelectorProps) => {
    const {style: styleProp, inverted, showTitle=true, languagesIDs} = props;
    const {t, i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [isFlagsVisible, setIsFlagsVisible] = useState(true);
    const style = useThemedStyleFunction(languageSelectorStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    // Map each language ID to its corresponding flag component
    const FLAG_COMPONENTS: Record<string, React.ComponentType<any>> = {
        en: FlagEnglish,
        sv: FlagSwedish,
        de: FlagGermany,
        // Add more language codes and their corresponding flag components here as needed
    };

    const availableLanguages = languagesIDs?.length ? languagesIDs : Object.keys(FLAG_COMPONENTS);
    
    useEffect(() => {
        // Automatically set language if there's only one
        if (languagesIDs && languagesIDs.length === 1) {
            const singleLanguage = languagesIDs[0];
            setSelectedLanguage(singleLanguage);
            i18n.changeLanguage(singleLanguage);
            setIsFlagsVisible(false);
        }
    }, [languagesIDs, i18n]);

    if (!isFlagsVisible) return null;

    return (
        <View style={containerStyle}>
            {showTitle && 
                <ThemedText style={[style.text, inverted && style.textInverted]}>
                    {t('chooseLanguage')}
                </ThemedText>
            }
            <View style={style.row}>
            {availableLanguages.map(code => {
                    const Icon = FLAG_COMPONENTS[code];
                    const selected = selectedLanguage === code;

                    return Icon ? (
                        <TouchableOpacity key={code}
                            style={[style.touchable, selected && (inverted ? style.selectedInverted : style.selected)]}
                            onPress={() => {
                                setSelectedLanguage(code);
                                i18n.changeLanguage(code);
                            }}>
                            <Icon style={style.flag}/>
                        </TouchableOpacity>
                    ) : null;  // Ensures only valid flags are rendered
                })}
            </View>
        </View>
    )
};

export { LanguageSelector };