import { COLORS } from './app.constants';

const SD_MAP_KEY = '016549e5-cc39-3abe-a778-467006e7cce1';
const HD_MAP_KEY = '1c07ef15981a483598b5b54d9c9e0d4f';

const ICONS = {
    PATHS: {
        CROSS_COUNTRY_SKIING: 'openlayers/icons/paths/path-cross-country-skiing.png',
        CROSS_COUNTRY_SKIING_CLOSED: 'openlayers/icons/paths/path-cross-country-skiing.png',
        SNOWMOBILING: 'openlayers/icons/paths/path-snowmobiling.png',
        MOUNTAIN_BIKING: 'openlayers/icons/paths/path-mountain-biking.png',
        HIKING: 'openlayers/icons/paths/path-hiking.png',
        ENDCAP: 'openlayers/icons/paths/path-endcap.png',
        RUNNING: 'openlayers/icons/paths/path-running.png',
        RIDING: 'openlayers/icons/paths/path-riding.png',
        CANOE: 'openlayers/icons/paths/path-canoe.png',
        CHAIR_LIFT: 'openlayers/icons/paths/path-chair-lift.png',
        GONDOLA: 'openlayers/icons/paths/path-gondola.png',
        SURFACE_LIFT: 'openlayers/icons/paths/path-surface-lift.png',
        BELT_LIFT: 'openlayers/icons/paths/path-belt-lift.png',
        SKI_SLOPE: 'openlayers/icons/paths/path-ski-slope.png',
    },
    POIS: {
        RESTAURANT: 'openlayers/icons/pois/poi-restaurants.png',
        ATTRACTIONS: 'openlayers/icons/pois/poi-attractions.png',
        PARKING: 'openlayers/icons/pois/poi-parking.png',
        LOOKOUTS: 'openlayers/icons/pois/poi-viewpoints.png',
        CAFES: 'openlayers/icons/pois/poi-cafe.png',
        CABINS: 'openlayers/icons/pois/poi-cabin.png',
        SKI_BUS: 'openlayers/icons/pois/poi-bus-stop.png',
        SHELTER: 'openlayers/icons/pois/poi-shelter.png',
        STORES: 'openlayers/icons/pois/poi-store.png',
        SNOWMOBILE_RENTAL: 'openlayers/icons/pois/poi-store.png',
        SPORTS_RENTAL: 'openlayers/icons/pois/poi-store.png',
        TOILETS: 'openlayers/icons/pois/poi-toilet.png',
        WARNINGS: 'openlayers/icons/pois/poi-warning.png',
        PLACE_OF_INTERESTS: 'openlayers/icons/pois/poi-place-of-interest.png',
        CLOSED: 'openlayers/icons/pois/poi-closed.png'
    },
    AOIS: {
        IMPORTANT: 'openlayers/icons/aois/important-area.png',
        WARNINGS: 'openlayers/icons/aois/closed-area.png',
        INFO: 'openlayers/icons/aois/area.png',

    },
    GPS_POSITION: 'openlayers/gps-position.png',
    DIRECTION_ARROW: 'openlayers/direction-arrow.png',
};

const PROPS = {
    PATHS: {
        DIFFICULTY: {
            veryEasy: COLORS.DIFFICULTY.VERY_EASY,
            easy: COLORS.DIFFICULTY.EASY,
            medium: COLORS.DIFFICULTY.MEDIUM,
            hard: COLORS.DIFFICULTY.HARD,
        },
        CATEGORY_1: {
            color: COLORS.PATHS.CROSS_COUNTRY_SKIING,
            icon: ICONS.PATHS.CROSS_COUNTRY_SKIING,
            closedIcon: ICONS.PATHS.CROSS_COUNTRY_SKIING_CLOSED,
        },
        CATEGORY_2: {
            color: COLORS.PATHS.SNOWMOBILING,
            icon: ICONS.PATHS.SNOWMOBILING,
            closedIcon: ICONS.PATHS.SNOWMOBILING,
        },
        CATEGORY_3: {
            color: COLORS.PATHS.MOUNTAIN_BIKING,
            icon: ICONS.PATHS.MOUNTAIN_BIKING,
            closedIcon: ICONS.PATHS.MOUNTAIN_BIKING,
        },
        CATEGORY_4: {
            color: COLORS.PATHS.HIKING,
            icon: ICONS.PATHS.HIKING,
            closedIcon: ICONS.PATHS.HIKING,
        },
        CATEGORY_5: {
            color: COLORS.PATHS.RUNNING,
            icon: ICONS.PATHS.RUNNING,
            closedIcon: ICONS.PATHS.RUNNING,
        },
        CATEGORY_6: {
            color: COLORS.PATHS.RIDING,
            icon: ICONS.PATHS.RIDING,
            closedIcon: ICONS.PATHS.RIDING,
        },
        CATEGORY_7: {
            color: COLORS.PATHS.CANOE,
            icon: ICONS.PATHS.CANOE,
            closedIcon: ICONS.PATHS.CANOE,
        },
        CATEGORY_8: {
            color: COLORS.PATHS.CHAIR_LIFT,
            icon: ICONS.PATHS.CHAIR_LIFT,
            closedIcon: ICONS.PATHS.CHAIR_LIFT,
        },
        CATEGORY_9: {
            color: COLORS.PATHS.GONDOLA,
            icon: ICONS.PATHS.GONDOLA,
            closedIcon: ICONS.PATHS.GONDOLA,
        },
        CATEGORY_10: {
            color: COLORS.PATHS.SURFACE_LIFT,
            icon: ICONS.PATHS.SURFACE_LIFT,
            closedIcon: ICONS.PATHS.SURFACE_LIFT,
        },
        CATEGORY_11: {
            color: COLORS.PATHS.BELT_LIFT,
            icon: ICONS.PATHS.BELT_LIFT,
            closedIcon: ICONS.PATHS.BELT_LIFT,
        },
        CATEGORY_12: {
            color: COLORS.PATHS.SKI_SLOPE,
            icon: ICONS.PATHS.SKI_SLOPE,
            closedIcon: ICONS.PATHS.SKI_SLOPE,
        },
    },
    POIS: {
        POI_CATEGORY_1: {
            color: COLORS.POIS.RESTAURANT,
            icon: ICONS.POIS.RESTAURANT,
            closedIcon: ICONS.POIS.RESTAURANT,
        },
        POI_CATEGORY_2: {
            color: COLORS.POIS.CAFES,
            icon: ICONS.POIS.CAFES,
            closedIcon: ICONS.POIS.CAFES,
        },
        POI_CATEGORY_3: {
            color: COLORS.POIS.ATTRACTIONS,
            icon: ICONS.POIS.PLACE_OF_INTERESTS,
            closedIcon: ICONS.POIS.PLACE_OF_INTERESTS,
        },
        POI_CATEGORY_4: {
            color: COLORS.POIS.LOOKOUTS,
            icon: ICONS.POIS.LOOKOUTS,
            closedIcon: ICONS.POIS.LOOKOUTS,
        },
        POI_CATEGORY_5: {
            color: COLORS.POIS.PARKING,
            icon: ICONS.POIS.PARKING,
            closedIcon: ICONS.POIS.PARKING,
        },
        POI_CATEGORY_6: {
            color: COLORS.POIS.CABINS,
            icon: ICONS.POIS.CABINS,
            closedIcon: ICONS.POIS.CABINS,
        },
        POI_CATEGORY_7: {
            color: COLORS.POIS.SKI_BUS,
            icon: ICONS.POIS.SKI_BUS,
            closedIcon: ICONS.POIS.SKI_BUS,
        },
        POI_CATEGORY_8: {
            // TODO: Allocate a separate color for shelters?
            color: COLORS.POIS.SHELTER,
            icon: ICONS.POIS.SHELTER,
            closedIcon: ICONS.POIS.SHELTER,
        },
        POI_CATEGORY_9: {
            color: COLORS.POIS.STORES,
            icon: ICONS.POIS.STORES,
            closedIcon: ICONS.POIS.STORES,
        },
        POI_CATEGORY_10: {
            color: COLORS.POIS.ATTRACTIONS,
            icon: ICONS.POIS.ATTRACTIONS,
            closedIcon: ICONS.POIS.ATTRACTIONS,
        },
        POI_CATEGORY_11: {
            // TODO: Allocate a separate color for break cabins?
            color: COLORS.POIS.CABINS,
            icon: ICONS.POIS.CABINS,
            closedIcon: ICONS.POIS.CABINS,
        },
        POI_CATEGORY_12: {
            color: COLORS.POIS.STORES,
            icon: ICONS.POIS.STORES,
            closedIcon: ICONS.POIS.STORES,
        },
        POI_CATEGORY_13: {
            color: COLORS.POIS.STORES,
            icon: ICONS.POIS.STORES,
            closedIcon: ICONS.POIS.STORES,
        },
        POI_CATEGORY_14: {
            color: COLORS.POIS.TOILETS,
            icon: ICONS.POIS.TOILETS,
            closedIcon: ICONS.POIS.TOILETS,
        },
        POI_CATEGORY_15: {
            color: COLORS.POIS.ATTRACTIONS,
            icon: ICONS.POIS.ATTRACTIONS,
            closedIcon: ICONS.POIS.ATTRACTIONS,
        },
        POI_CATEGORY_16: {
            color: COLORS.POIS.WARNING,
            icon: ICONS.POIS.WARNINGS,
            closedIcon: ICONS.POIS.WARNINGS,
        },
    },
    AOIS: {
        AOI_CATEGORY_1: {
            color: COLORS.AOIS.WARNING_AREA,
            icon: ICONS.AOIS.WARNINGS,
            closedIcon: ICONS.POIS.RESTAURANT,
        },
        AOI_CATEGORY_2: {
            color: COLORS.AOIS.IMPORTANT_AREA,
            icon: ICONS.AOIS.IMPORTANT,
            closedIcon: ICONS.AOIS.IMPORTANT,
        },
        AOI_CATEGORY_3: {
            color: COLORS.AOIS.INFO_AREA,
            icon: ICONS.AOIS.INFO,
            closedIcon: ICONS.AOIS.INFO,
        },
    }
};

const OBJECT_TYPE = 'objectType';
const OBJECT_ID = 'objectId';

export {
    SD_MAP_KEY,
    HD_MAP_KEY,
    ICONS,
    PROPS,
    OBJECT_TYPE,
    OBJECT_ID,
};