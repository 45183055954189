import { SettingsLanguagesResponse } from '../../interfaces';
import { getData } from "./base.utils"

/**
 * Gets settings page data
 */
export const getSettingsLanguges = (baseUrl: string): Promise<SettingsLanguagesResponse[]> => {
    const path = '/settings/languages';
    return new Promise<SettingsLanguagesResponse[]>((resolve, reject) => {
        getData<SettingsLanguagesResponse[]>(baseUrl, path).then((response) => {
            resolve(response);
        }).catch((error) => {
            console.log('Error fetching settings languages: ', error)
            resolve([])
        });
    }) 
};