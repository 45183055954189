import React from 'react';
import { View } from 'react-native';
import { AoiListContentProps } from '../../../interfaces';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { getAoiCategoryIcon } from '../../../utils';
import { useThemedStyleFunction } from '../../theme-provider';
import { ThemedText } from '../../themed-text';
import { listContentStyle } from './list-content.style';

const AoiListContent = (props: AoiListContentProps) => {
    const { category} = props;
    const name = getTranslatedProperty('name', props);
    const categoryName = getTranslatedProperty('name', category);
    const CategoryIcon = (props: any) => getAoiCategoryIcon(category.id, props)

    const style = useThemedStyleFunction(listContentStyle);

    return (
        <View style={style.container}>
            <CategoryIcon style={style.categoryIcon}/>
            <View style={style.content}>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText 
                        numberOfLines={1} 
                        ellipsizeMode="tail" 
                        type="bold" 
                        style={style.title}>
                            { name }
                    </ThemedText>
                </View>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText type="standard" style={style.subtitle}>{ categoryName }</ThemedText>
                </View>
            </View>
        </View>
    );
};

export { AoiListContent };